@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


@media print {

    html,
    body {
        display: none;
        /* hide whole page */
    }
}

.video-player {
    height: 400px;
    width: 100%;
}

@media (max-width:500px) {
    .video-player {
        height: 250px
    }
}

.rating-stars ul {
    list-style-type: none;
    padding: 0;

    -moz-user-select: none;
    -webkit-user-select: none;
}

.rating-stars ul>li.star {
    display: inline-block;

}

/* Idle State of the stars */
.rating-stars ul>li.star>i.fa {
    font-size: 2.5em;
    /* Change the size of the stars */
    color: #ccc;
    /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul>li.star.hover>i.fa {
    color: #FFCC36;
}

/* Selected state of the stars */
.rating-stars ul>li.star.selected>i.fa {
    color: #FF912C;
}

.react-pdf__Page__annotations,
.annotationLayer,
.react-pdf__Page__textContent {
    background: red;
    display: none !important;
    left: -10000px !important;
}








.faq .card {
    border-color: purple;
    margin-bottom: 10px;
}

.faq .card-header {
    color: #fff;
    cursor: pointer;
    background-color: #a4508b;
    background-image: linear-gradient(326deg, #a4508b 0%, #5f0a87 74%);

    /* background:none; */
    font-weight: bolder;
}

.faq .card-header:not(.collapsed) {
    /* background-color: purple; */
    background-color: #6b0f1a;
    background-image: linear-gradient(315deg, #6b0f1a 0%, #b91372 74%);


    color: #fff;
}

.faq .card h5 {
    color: inherit;
}

.faq .card .btn {
    color: inherit;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important
}

.player-wrapper>div {
    height: auto !important;
}

#videoLock {
    height: 75vh;
}

.kep-login-facebook {
    width: 100% !important;
    border-radius: 100px;
    text-align: center;
    background: none !important;
    color: #000 !important;
    background-color: rgb(255, 255, 255);
    display: none !important;
    align-items: center;
    border-color: #ccc !important;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;

    border: 1px solid transparent;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: 'Roboto' !important;
    text-transform: none !important
}

@media (max-width:500px) {
    .player-wrapper {
        height: auto !important
    }

    #videoLock {
        height: auto;
    }

}

.btn-info {
    color: #fff !important;
}

.btn-info:hover {
    color: #00bfd8 !important;
}

.playList {
    cursor: pointer;
}

.playList .active,
.playList li:hover {
    color: #00aaff
}

.login-link {
    color: rgb(124, 124, 124)
}

.login-link:hover {
    /* text-decoration: underline; */
    color: #000
}

.playList .icon {
    border: #00aaff solid 1px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #00aaff;
}

.playList .noLink {
    color: grey
}

.playList .noLink .icon {
    color: grey;
    border-color: grey;
}

.playList .noLink:hover {
    color: grey;
}

.value1 {
    color: grey !important;
    font-weight: 100 !important;
    text-decoration: line-through;
    font-size: 32px !important;
}



.mymodal {
    width: 80vw;
    height: 80vh;
}


.list-group-item.active h6,
.list-group-item.active small,
.list-group-item.active p {
    color: white !important;
}

.list-group-item:hover {
    background: #aaa;
}


.free-course {
    color: #f00;
    font-weight: bold;
    font-size: 80px;
    line-height: 1.2;
    /* animation: colorchange 4s infinite alternate; */
    background-image:
        linear-gradient(to right, red, red, navy, red, red);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-animation 10s linear infinite;
}

@keyframes rainbow-animation {
    to {
        background-position: 4500vh;
    }
}

.offer-purchase {
    font-size: 50px !important
}

@media (max-width:500px) {

    .free-course {
        animation: rainbow-animation 30s linear infinite;
        font-size: 50px;

    }

    .offer-purchase {
        font-size: 30px !important
    }
}


.free-course a {
    color: green;
}

.free-course a:hover {
    color: green;
}

@keyframes colorchange {
    0% {
        color: red;
    }



    50% {
        color: rgb(123, 2, 2);
    }

    100% {
        color: red;
    }
}

.review .rating-stars ul>li.star>i.fa {
    font-size: 20px;
}

.lock {
    font-size: 24px;
    color: #FF912C;
}

.player-wrapper video {
    max-height: 75vh;
    margin: auto;
}

.btn-outline-primary:hover {
    background-color: white !important;
    color: #0b5ed7 !important;
}

.btn-check:focus+.btn-outline-primary,
.btn-primary:focus,
.btn-outline-primary:focus {
    box-shadow: none !important
}